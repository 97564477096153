html,
body {
  font-family: disposable_droid_regular !important;
  background-color: black !important;
}

/* ================DEFINE CONTAINER================ */

:root {
  --color-yellow: #ffd541;
  --font-xsm: 12px;
  --font-sm: 14px;
  --font-base: 16px;
  --font-md: 20px;
  --font-lg: 24px;
  --font-xl: 30px;
  --font-2xl: 32px;
}

/*================================================= DEFINE-ALL = COLOR-VALUE =========================================== */
.text_yellow {
  color: var(--color-yellow);
}

.bg_yellow {
  background: var(--color-yellow);
}

/*=========================================== DEFINE-ALL = FONT-FAMILY ========================================== */

@font-face {
  font-family: emulogic;
  src: url("./assets/font/emulogic.ttf");
}

@font-face {
  font-family: disposable_droid_regular;
  src: url("./assets/font/DisposableDroidBB.ttf");
}

@font-face {
  font-family: disposable_droid_bold;
  src: url("./assets/font/DisposableDroidBB_bld.ttf");
}

.ff_emulogic {
  font-family: emulogic !important;
}

.ff_disposable_bold {
  font-family: disposable_droid_bold !important;
}

/*=========================================== DEFINE-ALL = FONT-SIZES ========================================== */
.text_xsm {
  font-size: var(--font-xsm);
}

.text_sm {
  font-size: var(--font-sm);
}

.text_base {
  font-size: var(--font-base);
}

.text_md {
  font-size: var(--font-md);
}

.text_lg {
  font-size: var(--font-lg);
}

.text_xl {
  font-size: var(--font-xl);
}

.text_2xl {
  font-size: var(--font-2xl);
}

/* =========DEFINE ALL COMMON CLASSES========= */
.sub_heading {
  font-size: var(--font-xl);
  line-height: 54px;
}

.opacity_08 {
  opacity: 0.8;
}
.lh_secondary {
  line-height: 26px;
}
.lh_primary {
  line-height: 24px;
}
.z_5 {
  z-index: 5px;
}

.common_btn {
  font-size: var(--font-sm);
  background-image: url('../src/assets/images/png/yellow-btn-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 15px 23px;
  background-color: transparent !important;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.common_btn:hover {
   filter: drop-shadow(0px 4px 25px rgba(255, 213, 65, 0.3));
}

/* =======================DEFINE ALL MEIDA QUERIES======================= */

@media (max-width: 1199.98px) {
  :root {
    --font-lg: 22px;
    --font-xl: 26px;
    --font-2xl: 26px;
  }

  .common_btn {
    padding: 14px 20px;
    line-height: 20px;
  }

  .sub_heading {
    line-height: 50px;
  }
}

@media (max-width: 991.98px) {
  :root {
    --font-md: 18px;
    --font-lg: 20px;
    --font-xl: 20px;
    --font-2xl: 22px;
  }

  .sub_heading {
    line-height: 48px;
  }
  .lh_secondary {
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-sm: 10px;
    --font-base: 14px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 20px;
  }

  .common_btn {
    padding: 10px 18px;
    line-height: 20px;
  }

  .sub_heading {
    line-height: 36px;
  }
  .lh_secondary {
    line-height: 20px;
  }
}

@media (min-width: 1100px) and (max-width: 1599.98px) {
  .custom_container {
    max-width: 1140px !important;
  }
}
/* DEIFINE FOOTER SECTION CODE */
.footer_icons {
  transition: all 0.5s ease-in-out;
}
.footer_icons:hover {
  transform: translateY(-8px);
}
.border_top {
  border-top: 1px solid #363636;
}
.mascot_coffee_img {
  left: -5%;
  top: -30%;
}

/* ======DEFINE REVENUE SECTION START====== */
.revenue_card {
  background-image: url("../src/assets/images/png/revenue-card-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 558px;
  height: 100%;
}
.revenue_para {
  max-width: 500px;
  line-height: 41.6px;
}
.left_corner_line {
  bottom: 16px;
  left: 16px;
}
.right_corner_line {
  top: 16px;
  right: 16px;
}
.mascot_confused_img {
  top: 3%;
  left: -4%;
  z-index: 0;
}
.performance_gif {
  top: -5%;
}
/* =========DEFINE JOIN SECTION CODE START========= */
.join_bg {
  background-image: url("../src/assets/images/png/jion-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 397px;
  margin: 353px 0px 284px 0px;
}
.mascot_pondering {
  top: -15%;
  left: 1%;
}
.mascot_loving {
  bottom: -25%;
  right: 5%;
}
.performance_right_gif {
  transform: scaleX(1);
}
.join_mascot_gif {
  top: -50%;
}
.revenue_top_spacing {
  padding-top: 196px;
}
/* ------------------------backtotop------------------------ */
.top_scroll {
  position: fixed;
  right: 15px;
  bottom: 25px;
  background: var(--color-yellow);
  height: 40px;
  width: 40px;
  z-index: 100;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear;
  -webkit-animation: move 2s ease-in-out infinite;
  -webkit-animation: up-down 2.5s ease-in-out infinite;
  animation: up-down 2.5s ease-in-out infinite;
}

.top_scroll:hover {
  box-shadow: 2px 0px 10px 1px var(--color-yellow);
}

@keyframes up-down {
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.dot_image {
  animation: show_hide_dots 4s linear infinite; /* Faster animation */
}

.dot_image.one {
  animation-delay: 0.1s;
}

.dot_image.two {
  animation-delay: 0.3s;
}

.dot_image.three {
  animation-delay: 0.5s;
}

.dot_image.four {
  animation-delay: 0.7s;
}

.dot_image.five {
  animation-delay: 0.9s;
}

.dot_image.six {
  animation-delay: 1.1s;
}

@keyframes show_hide_dots {
  0%, 49.9% {
    opacity: 0;
  }

  50%, 100% {
    opacity: 1;
  }

  99.9% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@media (max-width: 1199.98px) {
  .revenue_card {
    max-width: unset;
  }
  .revenue_para {
    line-height: 32px;
  }

  .join_bg {
    min-height: 350px;
    margin: 130px 0px;
  }

  .revenue_top_spacing {
    padding-top: 130px;
  }
}
@media (max-width: 991.98px) {
  .revenue_img {
    width: 44px;
  }
  .revenue_para {
    line-height: 24px;
  }
  .mascot_confused_img {
    top: 5%;
    left: -2%;
    width: 100px;
    height: 100px;
  }
  .performance_gif {
    width: 130px !important;
  }
  .join_bg {
    min-height: 300px;
    margin: 100px 0px;
  }
  .revenue_top_spacing {
    padding-top: 100px;
  }
  .mascot_pondering {
    top: -12%;
    width: 120px;
  }
  .mascot_loving {
    width: 120px;
    bottom: -15%;
  }
  .animated_logo,
  .loader_gif {
    width: 120px;
  }
  .loader_gif_animation {
    right: 2%;
  }
}
@media (max-width: 767.98px) {
  .mascot_pondering {
    width: 100px;
  }
  .mascot_loving {
    width: 100px;
    bottom: -15%;
  }
  .mascot_coffee_img {
    width: 100px;
    height: 100px;
    top: -20%;
  }

  .footer_icons svg {
    max-width: 36px;
  }
  .footer_logo {
    max-width: 150px;
  }
  .left_corner_line {
    bottom: 8px;
    left: 8px;
  }
  .right_corner_line {
    top: 8px;
    right: 8px;
  }
  .w_xsm_100 {
    width: 74%;
  }

}
@media (max-width: 575.98px) {
  .mascot_coffee_img {
    width: 80px;
    height: 80px;
    top: -20%;
    left: 3%;
  }
  .mascot_confused_img {
    width: 80px;
    height: 80px;
    top: -10%;
    left: 3%;
  }
  .footer_icons svg {
    max-width: 32px;
  }
  .performance_gif {
    top: -1%;
    width: 80px !important;
  }

  .join_bg {
    min-height: 280px;
    margin: 60px 0px;
  }
  .revenue_top_spacing {
    padding-top: 60px;
  }
  .mascot_loving {
    width: 80px;
    bottom: -10%;
    right: 0px;
  }
  .mascot_pondering {
    width: 80px;
    top: -8%;
    left: 3%;
  }
  .top_scroll {
    width: 32px;
    height: 32px;
  }
  .top_scroll svg {
    width: 24px;
    height: 24px;
  }

  .animated_logo,
  .loader_gif {
    width: 100px;
  }
}
@media (min-width: 1920px) {
  .max_w_1920 {
    max-width: 1920px !important;
    margin: 0 auto;
  }
  .hero_h {
    max-height: 1080px;
  }
  .top_left_gif {
    top: 24%;
    left: 4% !important;
  }
}

