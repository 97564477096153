.feature_section {
  padding-top: 80px;
  padding-bottom: 60px;
}
/* header css start here */
.nav_logo {
  height: auto !important;
  width: 82% !important;
  max-width: 158px !important;
}
.header_bg {
  background-image: url("../src/assets/images/png/header-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.header_bg nav {
  padding: 22px 12px !important;
}
.nav_link a {
  transition: all 300ms linear;
}
.nav_link a:hover {
  opacity: 1;
  color: var(--color-yellow) !important;
}
.opacity_80 {
  opacity: 80%;
}
/* hero section css start here */
.hero_heading {
  line-height: 188%;
  font-size: 16px;
}
.yellow_button {
  color: black;
  background-image: url("../src/assets/images/png/yellow-btn-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  border: none;
  transition: all 300ms linear;
  line-height: 20px;
}
.yellow_button:hover {
  filter: drop-shadow(0px 4px 25px rgba(255, 213, 65, 0.3));
}

.transparent_button {
  color: var(--color-yellow);
  line-height: 20px;
  border: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../src/assets/images/png/transparent-btn-bg.png");
  transition: all 0.3s ease-in-out;
}
.transparent_button:hover{
  filter: drop-shadow(0px 4px 25px rgba(255, 213, 65, 0.3));
}

/* feature section start here */
.feature_cards {
  max-width: 400px;
  margin: 0 auto;
}
.feature_para {
  line-height: 125%;
}
.feature_cards p {
  line-height: 125%;
}
.feature_card_box {
  background-size: 100% 100%;
  min-height: 240px;

}
.feature_card_box img{
  transition: all 300ms linear;
}
.feature_card_box p{
  transition: all 300ms linear;
}
.feature_card_box:hover p{
  color: var(--color-yellow) !important;
}
.feature_card_box:hover img{
  scale: 1.2;
}
.feature_card_box {
  background-image: url("../src/assets/images/png/feature-cards-bg.png");
  background-repeat: no-repeat;
}

.mascot_hey_icon {
  top: 2%;
}
/* performance section start here */
.performace_card {
  padding: 2px;
  background-image: url("../src/assets/images/png/performance-card-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.performace_card div ul li {
  line-height: 21px;
}
.performace_card div {
  padding: 24px;
  border-radius: 19px;
}

.performance_gif {
  transform: scaleX(-1);
}
.loader {
  bottom: 0;
  left: 0;
  overflow: hidden;

  right: 0;
  top: 0;
  z-index: 99999;
}

@media (min-width: 576px) {
  .nav_logo {
    height: auto !important;
    width: 100% !important;
  }
  .hero_heading {
    font-size: 24px;
  }
  .feature_cards {
    max-width: unset;
  }
}
@media (min-width: 768px) {
  .nav_logo {
    height: auto !important;
    width: 100% !important;
  }
  .hero_right_gif {
    max-width: 190px;
    right: -5%;
    bottom: -40%;
  }
  .hero_left_img {
    left: -7%;
    bottom: -30%;
    max-width: 180px;
  }
  .top_left_gif {
    top: -10%;
    left: 1%;
  }
  .performance_top_left_gif {
    top: -8%;
    left: 1%;
  }
  .feature_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .performance_features_gif {
    top: 18%;
  }
}
@media (min-width: 992px) {
  .feature_para {
    line-height: unset;
  }
  .feature_cards p {
    line-height: unset;
  }
  .hero_heading {
    font-size: 32px;
  }
  .hero_h {
    height: calc(100vh - 110px);
  }
  .hero_right_gif {
    max-width: 260px;
    right: -3%;
    bottom: -40%;
  }
  .hero_left_img {
    left: -4%;
    bottom: -30%;
    max-width: 200px;
  }
  .top_left_gif {
    top: -30%;
    left: 1% !important;
  }
  .feature_section {
    padding-top: 50px;
    padding-bottom: 130px;
  }
  .performance_features_gif {
    top: 10%;
  }
}
@media (min-width: 1200px) {
  .hero_left_img {
    left: -10%;
    bottom: -36%;
    max-width: 235px;
  }
  .top_left_gif {
    top: 24%;
  }
  .feature_section {
    padding-top: 125px;
    padding-bottom: 270px;
  }
}
.hero_dots {
  left: 82%;
  top: 160px;
}
.feature_dot_gif {
  right: 68% !important;
  top: 170px;
}
.performance_dots {
  left: 120px;
  top: 160px;
}
.join_dots {
  left: 80%;
  top: 200px;
}

.revenue_right_gif {
  right: 68%;
  top: 165px;
}
.footer_dots {
  right: 68%;
  top: 165px;
}
